import { Page404, Wrapper } from "@royalcanin-fr-loyalty/ui-kit";
import React from "react";
import { Layout } from "../components/Layout";
import { withPage } from "../lib/withPage";

const Error404 = ({
  layoutProps,
  hideHomeButton,
}: {
  layoutProps?: Omit<React.ComponentProps<typeof Layout>, "children">;
  hideHomeButton?: boolean;
}) => (
  <Layout {...layoutProps}>
    <Wrapper>
      <Page404 hideHomeButton={hideHomeButton} />
    </Wrapper>
  </Layout>
);

export default withPage(Error404);
